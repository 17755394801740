<template>
  <div
    class="recipients-display"
    ref="container"
  >
    <div
      class="recipients-list"
      ref="recipients"
    >
      <span
        v-for="(item, index) in displayedTrimmedData"
        :key="index"
        >{{ item }}<span v-if="index < displayedTrimmedData.length - 1">, </span></span
      >
      <span v-if="trimmedCount > 0">, ...</span>
    </div>
    <SupplierBadge
      v-if="trimmedCount > 0"
      :count="trimmedCount"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
      @click="toggleTooltip"
    />
    <div
      v-if="tooltipVisible"
      class="recipients-tooltip"
    >
      {{ recipients.join(', ') }}
    </div>
  </div>
</template>

<script>
import SupplierBadge from './SupplierBadge.vue';

export default {
  components: {
    SupplierBadge,
  },
  props: {
    recipients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      displayedTrimmedData: [],
      trimmedCount: 0,
      tooltipVisible: false,
    };
  },
  mounted() {
    this.calculateRecipients();
    window.addEventListener('resize', this.calculateRecipients);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateRecipients);
  },
  methods: {
    calculateRecipients() {
      const containerWidth = this.$refs.container.clientWidth;
      let currentWidth = 0;
      let visibleRecipients = [];
      let trimmed = 0;

      for (let i = 0; i < this.recipients.length; i++) {
        const emailWidth = this.getDataWidth(this.recipients[i] + ', ');
        if (currentWidth + emailWidth > containerWidth) {
          if (i === 0) {
            visibleRecipients.push(
              this.truncateEmail(this.recipients[i], containerWidth)
            );
          }
          trimmed = this.recipients.length - i;
          break;
        }
        visibleRecipients.push(this.recipients[i]);
        currentWidth += emailWidth;
      }
      this.displayedTrimmedData = visibleRecipients;
      this.trimmedCount = trimmed;
    },
    getDataWidth(email) {
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'nowrap';
      span.innerText = email;
      document.body.appendChild(span);
      const width = span.clientWidth;
      document.body.removeChild(span);
      return width;
    },
    truncateEmail(email, maxWidth) {
      let truncated = email;
      while (
        this.getDataWidth(truncated + '...') > maxWidth &&
        truncated.length > 0
      ) {
        truncated = truncated.slice(0, -1);
      }
      return truncated + '...';
    },
    toggleTooltip() {
        this.tooltipVisible = !this.tooltipVisible;
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
  },
};
</script>

<style>
.recipients-display {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.recipients-tooltip {
  position: absolute;
  top: -3rem;
  right: -8rem;
  padding: 8px 16px;
  background-color: #666;
  color: #f0f0f0;
  border-radius: 24px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>
