<template>
  <div
    class="recipients-badge"
    @click="handleClick"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    +{{ count }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  methods: {
    showTooltip() {
        this.$emit('mouseover')
    },
    hideTooltip() {
        this.$emit('mouseleave')
    },
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>
.recipients-badge {
  margin-left: 8px;
  padding: 4px 8px;
  background-color: #52cec2;
  color: white;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}
</style>
